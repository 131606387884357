"use client";
import { jsx as f } from "react/jsx-runtime";
import x, { useContext as d, useMemo as l, useEffect as C } from "react";
import { c as o } from "../../http-client-CHCvaZ1A.js";
const s = x.createContext(o());
function h({ children: a, config: t, onInit: e }) {
  const { baseURL: n, timeout: p, headers: u, credentials: c, appName: i, appVersion: m } = t, r = l(() => o(t), [n, p, u, c, i, m]);
  return C(() => {
    e && e(r);
  }, []), /* @__PURE__ */ f(s.Provider, { value: r, children: a });
}
function R() {
  return d(s);
}
export {
  h as HttpContextProvider,
  R as default
};
