"use client";
var Ee = Object.defineProperty;
var Le = (t, r, n) => r in t ? Ee(t, r, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[r] = n;
var C = (t, r, n) => Le(t, typeof r != "symbol" ? r + "" : r, n);
import le, { useCallback as g, useEffect as K, useMemo as Re } from "react";
import { jsxs as Fe, Fragment as Ne, jsx as ee } from "react/jsx-runtime";
function fe(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { actionSource: "string" }
    ]);
    return e.offerId ? {
      request: {
        schema: "iglu:pl.pracuj.www/addfavorites/jsonschema/1-0-1",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId,
          actionSource: e.actionSource
        }
      },
      hasImportant: !1
    } : r();
  };
}
function pe(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(
      a,
      [{ offerId: "int" }, { source: "string" }]
    );
    if (!e.offerId || e.source && !E[e.source])
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/applicationconfirmation/jsonschema/2-0-1",
      data: {
        pageviewId: n.previousPageviewId,
        offerId: e.offerId
      }
    };
    return e.source !== void 0 && (i.data.source = e.source), {
      request: i,
      hasImportant: !1
    };
  };
}
function ge(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [{ offerId: "int" }]);
    return e.offerId ? {
      request: {
        schema: "iglu:pl.pracuj.www/applyview/jsonschema/2-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId
        }
      },
      hasImportant: !1
    } : r();
  };
}
function he(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { cvSource: "string" },
      { source: "string" }
    ]);
    if (e.source && !E[e.source])
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/applyviewsendclick/jsonschema/2-0-1",
      data: {
        pageviewId: n.previousPageviewId,
        offerId: e.offerId,
        cvSource: e.cvSource
      }
    };
    return e.source !== void 0 && (i.data.source = e.source), {
      request: i,
      hasImportant: !1
    };
  };
}
function ve(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { atsUrl: "string" }
    ]);
    return !e.offerId || !e.atsUrl ? r() : {
      request: {
        schema: "iglu:pl.pracuj.www/atsclick/jsonschema/2-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          atsUrl: e.atsUrl,
          offerId: e.offerId
        }
      },
      hasImportant: !1
    };
  };
}
function me(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { source: "string" },
      { action: "string" },
      { agreements: "array" }
    ]), i = {
      schema: "iglu:pl.pracuj.www/cookieagreementsmodal/jsonschema/1-0-0",
      data: {
        pageviewId: n.previousPageviewId,
        source: e.source,
        action: e.action,
        agreements: e.agreements || []
      }
    };
    return e.source !== void 0 && (i.data.source = e.source), {
      request: i,
      hasImportant: !1
    };
  };
}
function we(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { applicationProcessId: "string" },
      { currentStep: "string" },
      { oneClickApply: "string" },
      { previousStep: "string" },
      { applicationId: "int" },
      { attachedCV: "int" },
      { eRecruiterApplicationProcessId: "string" },
      { eRecruiterFormAgreement: "string" },
      { eRecruiterFormType: "string" },
      { surveyQuestionsAnswered: "int" },
      { surveyQuestionCount: "int" },
      { userLinkAvailable: "int" },
      { userLinkAnswered: "int" }
    ]);
    if (!N[e.currentStep] || !N[e.previousStep] || !e.offerId || !e.applicationProcessId || !e.oneClickApply)
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/currentstepinapplicationprocess/jsonschema/1-0-7",
      data: {
        pageviewId: n.previousPageviewId,
        offerId: e.offerId,
        currentStep: e.currentStep,
        previousStep: e.previousStep,
        applicationProcessId: e.applicationProcessId,
        oneClickApply: e.oneClickApply
      }
    };
    return [
      { name: "applicationId", value: e.applicationId },
      { name: "attachedCV", value: e.attachedCV },
      { name: "surveyQuestionCount", value: e.surveyQuestionCount },
      { name: "surveyQuestionsAnswered", value: e.surveyQuestionsAnswered },
      { name: "userLinkAvailable", value: e.userLinkAvailable },
      { name: "userLinkAnswered", value: e.userLinkAnswered },
      { name: "eRecruiterApplicationProcessId", value: e.eRecruiterApplicationProcessId },
      { name: "eRecruiterFormType", value: e.eRecruiterFormType },
      { name: "eRecruiterFormAgreement", value: e.eRecruiterFormAgreement }
      // TODO: Fix that
      // eslint-disable-next-line unicorn/no-array-for-each
    ].forEach(({ name: d, value: o }) => {
      o !== void 0 && (i.data[d] = o);
    }), {
      request: i,
      hasImportant: !1
    };
  };
}
function ye(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(
      a,
      [
        { authProcessId: "string" },
        { processCurrentStage: "string" },
        { accountType: "string" },
        { processType: "string" },
        { isApplicationProcess: "boolean" }
      ]
    );
    return {
      request: {
        schema: "iglu:pl.pracuj.www/currentstepinauthprocess/jsonschema/1-0-1",
        data: {
          pageviewId: n.previousPageviewId,
          authProcessId: e.authProcessId,
          processCurrentStage: e.processCurrentStage,
          accountType: e.accountType,
          processType: e.processType,
          isApplicationProcess: e.isApplicationProcess
        }
      },
      hasImportant: !1
    };
  };
}
function Ie(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { authProcessId: "string" },
      { onboardingProcessId: "string" },
      { processCurrentStage: "string" },
      { triggeredBy: "string" },
      { jobTitle: "string" },
      { workModeType: "array" },
      { jobContractType: "array" },
      { isSkipped: "boolean" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.www/currentstepinonboardingprocess/jsonschema/1-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          authProcessId: e.authProcessId,
          onboardingProcessId: e.onboardingProcessId,
          processCurrentStage: e.processCurrentStage,
          triggeredBy: e.triggeredBy,
          jobTitle: e.jobTitle,
          workModeType: e.workModeType,
          jobContractType: e.jobContractType,
          isSkipped: e.isSkipped
        }
      },
      hasImportant: !1
    };
  };
}
function Pe(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { applicationProcessId: "string" },
      { referrerApplicationProcessId: "string" },
      { multiApplyOfferIds: "array" },
      { currentStep: "string" },
      { oneClickApply: "string" },
      { previousStep: "string" },
      { attachedCV: "int" },
      { surveyQuestionsAnswered: "int" },
      { surveyQuestionCount: "int" },
      { userLinkAvailable: "int" },
      { userLinkAnswered: "int" }
    ]);
    if (!N[e.currentStep] || !N[e.previousStep] || !e.applicationProcessId || !e.oneClickApply)
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/currentstepinmultiapplicationprocess/jsonschema/1-0-0",
      data: {
        pageviewId: n.previousPageviewId,
        applicationProcessId: e.applicationProcessId,
        currentStep: e.currentStep,
        previousStep: e.previousStep,
        oneClickApply: e.oneClickApply
      }
    };
    return [
      { name: "referrerApplicationProcessId", value: e.referrerApplicationProcessId },
      { name: "multiApplyOfferIds", value: e.multiApplyOfferIds },
      { name: "attachedCV", value: e.attachedCV },
      { name: "surveyQuestionCount", value: e.surveyQuestionCount },
      { name: "surveyQuestionsAnswered", value: e.surveyQuestionsAnswered },
      { name: "userLinkAvailable", value: e.userLinkAvailable },
      { name: "userLinkAnswered", value: e.userLinkAnswered }
      // Fix that
      // eslint-disable-next-line unicorn/no-array-for-each
    ].forEach(({ name: d, value: o }) => {
      o !== void 0 && (i.data[d] = o);
    }), {
      request: i,
      hasImportant: !1
    };
  };
}
function Ae(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" }
    ]);
    return e.offerId ? {
      request: {
        schema: "iglu:pl.pracuj.www/erecruiterformfinish/jsonschema/2-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId
        }
      },
      hasImportant: !1
    } : r();
  };
}
function J(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { pageviewId: "string" },
      { section: "string" },
      { position: "number" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.www/eventclick/jsonschema/1-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          section: e.section,
          position: e.position
        }
      },
      hasImportant: !1
    };
  };
}
function ke(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { actionSource: "string" }
    ]);
    return !e.offerId || !e.actionSource ? r() : {
      request: {
        schema: "iglu:pl.pracuj.www/ignorerecommendation/jsonschema/2-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId,
          actionSource: e.actionSource
        }
      },
      hasImportant: !1
    };
  };
}
function be(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { isArchive: "bool" },
      { searchId: "string" },
      { source: "string" },
      { searchHash: "string" }
    ]);
    if (!e.offerId || e.source && !E[e.source])
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/offerview/jsonschema/2-0-2",
      data: {
        pageviewId: n.previousPageviewId,
        offerId: e.offerId,
        isArchive: e.isArchive
      }
    };
    return e.source !== void 0 && (i.data.source = e.source), e.searchId !== void 0 && (i.data.searchId = e.searchId), e.searchHash !== void 0 && (i.data.searchHash = e.searchHash), {
      request: i,
      hasImportant: !1
    };
  };
}
function Ce(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { appType: "int" },
      { applyUrl: "string" },
      { source: "string" },
      { searchId: "string" }
    ]);
    if (!e.offerId || e.source && !E[e.source])
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/offerviewclick/jsonschema/2-0-2",
      data: {
        pageviewId: n.previousPageviewId,
        offerId: e.offerId,
        appType: e.appType,
        applyUrl: e.applyUrl
      }
    };
    return e.source !== void 0 && (i.data.source = e.source), e.searchId !== void 0 && (i.data.searchId = e.searchId), {
      request: i,
      hasImportant: !1
    };
  };
}
function _e(t) {
  return (r, n, a) => {
    const c = t.setArgs(n, [
      { isTranslatable: "bool" }
    ]), e = {
      schema: "iglu:pl.pracuj.tracker/pageview/jsonschema/3-0-0",
      data: {}
    }, { isTranslatable: i } = c;
    return r.previousPageviewId && (e.data.previousPageviewId = r.previousPageviewId), i && r.appLanguage && (e.data.lan = r.appLanguage), t.setPVID(a), {
      request: e,
      hasImportant: !1
    };
  };
}
function je(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { testID: "string" },
      { testGroup: "string" },
      { applicationProcessId: "string" },
      { page: "string" }
    ]);
    if (!e.testID || !e.testGroup || !E[e.page])
      return r();
    const i = {
      schema: "iglu:pl.pracuj.www/pageviewtestgroup/jsonschema/1-0-0",
      data: {
        pageviewId: n.previousPageviewId,
        testID: e.testID,
        testGroup: e.testGroup,
        page: e.page
      }
    };
    return e.applicationProcessId !== void 0 && (i.data.applicationProcessId = e.applicationProcessId), {
      request: i,
      hasImportant: !1
    };
  };
}
function Se(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { source: "string" },
      { context: "string" },
      { limit: "int" },
      { size: "int" },
      { offset: "int" },
      { total: "int" },
      { group: "string" },
      { testName: "string" },
      { offers: "array" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.www/recommendationview/jsonschema/1-0-2",
        data: {
          pageviewId: n.previousPageviewId,
          source: e.source,
          context: e.context,
          limit: e.limit,
          size: e.size,
          offset: e.offset,
          total: e.total,
          group: e.group,
          testName: e.testName,
          offers: e.offers
        }
      },
      hasImportant: !1
    };
  };
}
function X(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { profileId: "string" },
      { actionSource: "string" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.pracodawcy/profileview/jsonschema/2-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          actionSource: e.actionSource,
          profileId: e.profileId
        }
      },
      hasImportant: !1
    };
  };
}
function Me(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { pageviewId: "string" },
      { action: "string" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.www/recruitmentassistant/jsonschema/1-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          action: e.action
        }
      },
      hasImportant: !1
    };
  };
}
function qe(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(
      a,
      [{ mpUserId: "int" }, { emailOriginId: "int" }, { registrationSourceId: "int" }, { accountType: "string" }]
    );
    return {
      request: {
        schema: "iglu:pl.pracuj.www/registrationinformation/jsonschema/1-0-2",
        data: {
          pageviewId: n.previousPageviewId,
          mpUserId: e.mpUserId,
          emailOriginId: e.emailOriginId,
          registrationSourceId: e.registrationSourceId,
          accountType: e.accountType
        }
      },
      hasImportant: !1
    };
  };
}
function De(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" },
      { actionSource: "string" }
    ]);
    return e.offerId ? {
      request: {
        schema: "iglu:pl.pracuj.www/removefavorites/jsonschema/1-0-1",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId,
          actionSource: e.actionSource
        }
      },
      hasImportant: !1
    } : r();
  };
}
function Oe(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { offerId: "int" }
    ]);
    return e.offerId ? {
      request: {
        schema: "iglu:pl.pracuj.www/showphonenumberclick/jsonschema/3-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          offerId: e.offerId
        }
      },
      hasImportant: !1
    } : r();
  };
}
function Te(t, r) {
  return (n, a) => {
    if (!a[2])
      return r();
    const e = t.setArgs(a, [
      { name: "string" },
      { value: "int" },
      { delta: "int" },
      { id: "string" }
    ]);
    return {
      request: {
        schema: "iglu:pl.pracuj.www/webvitals/jsonschema/1-0-0",
        data: {
          pageviewId: n.previousPageviewId,
          name: e.name,
          value: e.value,
          delta: e.delta,
          id: e.id
        }
      },
      hasImportant: !1
    };
  };
}
const ct = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  addFavorites: fe,
  applicationConfirmation: pe,
  applyView: ge,
  applyViewSendClick: he,
  atsClick: ve,
  cookieAgreementsModal: me,
  currentStepInApplicationProcess: we,
  currentStepInAuthProcess: ye,
  currentStepInMultiApplicationProcess: Pe,
  currentStepInOnboardingProcess: Ie,
  eRecruiterFormFinish: Ae,
  employersProfileView: X,
  eventClick: J,
  ignoreRecommendation: ke,
  offerView: be,
  offerViewClick: Ce,
  pageView: _e,
  pageViewTestGroup: je,
  recommendationView: Se,
  recruitmentAssistant: Me,
  registrationInformation: qe,
  removeFavorites: De,
  showPhoneNumberClick: Oe,
  webVitals: Te
}, Symbol.toStringTag, { value: "Module" }));
function h(t, r) {
  if (window.gptr)
    try {
      window.gptr("send", t, r);
    } catch {
      console.warn("Tracker not available");
    }
}
function Ve(t, r) {
  if (window.gptr)
    try {
      window.gptr("set", t, r);
    } catch {
      console.warn("Tracker not available");
    }
}
function He(t) {
  if (!window.gptr)
    return t();
  try {
    return window.gptr("set", "callback", () => t());
  } catch {
    return t();
  }
}
function Y() {
  return `
        !(function () {
          try {
            (window.GPtracker = 'gptr'),
              window.GPtracker in window ||
                ((window[window.GPtracker] = function () {
                  window[window.GPtracker].q.push({
                    ts: new Date().getTime(),
                    args: arguments,
                  }),
                    'object' == typeof window[window.GPtracker].tr && window[window.GPtracker].tr.track();
                }),
                (window[window.GPtracker].q = []));
          } catch (w) {
            throw new Error(w);
          }
        })();
      `;
}
function Z(t) {
  return ({ args: r, uuid: n, ts: a }, c) => {
    const [e, i] = r;
    function d() {
      return !1;
    }
    function o(u) {
      return u(t, d);
    }
    const l = {
      navigationeventclick: o(J),
      recruitmentassistant: o(Me),
      eventclick: o(J),
      cookieagreementsmodal: o(
        me
      ),
      webvitals: o(Te),
      pageview: o(_e),
      pageviewtestgroup: o(je),
      offerview: o(be),
      offerviewclick: o(Ce),
      applyview: o(ge),
      applyviewsendclick: o(he),
      atsclick: o(ve),
      addfavorites: o(fe),
      removefavorites: o(De),
      ignorerecommendation: o(ke),
      employersprofileview: o(X),
      profileview: o(X),
      recommendationview: o(Se),
      applicationconfirmation: o(
        pe
      ),
      erecruiterformfinish: o(Ae),
      currentstepinapplicationprocess: o(we),
      currentstepinmultiapplicationprocess: o(Pe),
      currentstepinauthprocess: o(
        ye
      ),
      currentstepinonboardingprocess: o(Ie),
      registrationinformation: o(
        qe
      ),
      showphonenumberclick: o(Oe),
      invalid: d
    };
    return (l[i] || l.invalid)(c, r, n, a);
  };
}
function ut({
  appName: t,
  appVersion: r,
  appLanguage: n,
  cookieDomain: a,
  collectorEndpoint: c,
  coreUrl: e,
  cspNonce: i,
  enabled: d,
  userId: o = 0
}) {
  const l = g(() => {
    const u = window.gptr.tr;
    u !== void 0 && u.install({
      schemaParser: Z(u),
      defaults: {
        businessId: o && String(o) || "",
        appName: t,
        appVersion: r,
        appLanguage: n
      },
      cookieDomain: a,
      srvURL: c
    });
  }, []);
  return K(() => {
    if (!d)
      return () => null;
    const u = document.createElement("script");
    return u.innerHTML = Y(), u.id = "tracker-script", u.nonce = i, document.body.append(u), () => {
      u.remove();
    };
  }, []), K(() => {
    if (!d)
      return () => null;
    const u = document.createElement("script");
    return u.src = e, u.async = !0, u.nonce = i, u.addEventListener("load", l), document.body.append(u), () => {
      u.remove();
    };
  }, []), null;
}
function Ue(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var U = { exports: {} }, x = {}, te;
function xe() {
  if (te) return x;
  te = 1, Object.defineProperty(x, "__esModule", {
    value: !0
  }), x.default = t;
  function t() {
    return r.apply(this, arguments);
  }
  function r() {
    return r = Object.assign || function(n) {
      for (var a = 1; a < arguments.length; a++) {
        var c = arguments[a];
        for (var e in c)
          Object.prototype.hasOwnProperty.call(c, e) && (n[e] = c[e]);
      }
      return n;
    }, r.apply(this, arguments);
  }
  return x;
}
var B = {}, re;
function Be() {
  if (re) return B;
  re = 1, Object.defineProperty(B, "__esModule", {
    value: !0
  }), B.default = t;
  function t(n, a) {
    if (!a && n && n.__esModule)
      return n;
    if (n === null || typeof n != "object" && typeof n != "function")
      return {
        default: n
      };
    var c = r(a);
    if (c && c.has(n))
      return c.get(n);
    var e = {}, i = Object.defineProperty && Object.getOwnPropertyDescriptor;
    for (var d in n)
      if (d !== "default" && Object.prototype.hasOwnProperty.call(n, d)) {
        var o = i ? Object.getOwnPropertyDescriptor(n, d) : null;
        o && (o.get || o.set) ? Object.defineProperty(e, d, o) : e[d] = n[d];
      }
    return e.default = n, c && c.set(n, e), e;
  }
  function r(n) {
    if (typeof WeakMap != "function") return null;
    var a = /* @__PURE__ */ new WeakMap(), c = /* @__PURE__ */ new WeakMap();
    return (r = function(e) {
      return e ? c : a;
    })(n);
  }
  return B;
}
var Q = {}, ne;
function Qe() {
  if (ne) return Q;
  ne = 1, Object.defineProperty(Q, "__esModule", {
    value: !0
  }), Q.default = t;
  function t(r, n) {
    if (r == null) return {};
    var a = {}, c = Object.keys(r), e, i;
    for (i = 0; i < c.length; i++)
      e = c[i], !(n.indexOf(e) >= 0) && (a[e] = r[e]);
    return a;
  }
  return Q;
}
var F = {}, G = {}, ae;
function Ge() {
  if (ae) return G;
  ae = 1, Object.defineProperty(G, "__esModule", {
    value: !0
  }), G.default = t;
  function t(r) {
    return r && r.__esModule ? r : {
      default: r
    };
  }
  return G;
}
var oe;
function ze() {
  if (oe) return F;
  oe = 1, Object.defineProperty(F, "__esModule", {
    value: !0
  }), F.HeadManagerContext = void 0;
  var t = Ge().default, r = t(le);
  const n = r.default.createContext({});
  return F.HeadManagerContext = n, process.env.NODE_ENV !== "production" && (n.displayName = "HeadManagerContext"), F;
}
var z = { exports: {} }, ie;
function We() {
  return ie || (ie = 1, function(t, r) {
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.default = n, r.isEqualNode = e, r.DOMAttributeNames = void 0;
    function n() {
      return {
        mountedInstances: /* @__PURE__ */ new Set(),
        updateHead: (d) => {
          const o = {};
          d.forEach((f) => {
            if (
              // If the font tag is loaded only on client navigation
              // it won't be inlined. In this case revert to the original behavior
              f.type === "link" && f.props["data-optimized-fonts"]
            ) {
              if (document.querySelector(`style[data-href="${f.props["data-href"]}"]`))
                return;
              f.props.href = f.props["data-href"], f.props["data-href"] = void 0;
            }
            const p = o[f.type] || [];
            p.push(f), o[f.type] = p;
          });
          const l = o.title ? o.title[0] : null;
          let u = "";
          if (l) {
            const { children: f } = l.props;
            u = typeof f == "string" ? f : Array.isArray(f) ? f.join("") : "";
          }
          u !== document.title && (document.title = u), [
            "meta",
            "base",
            "link",
            "style",
            "script"
          ].forEach((f) => {
            i(f, o[f] || []);
          });
        }
      };
    }
    const a = {
      acceptCharset: "accept-charset",
      className: "class",
      htmlFor: "for",
      httpEquiv: "http-equiv",
      noModule: "noModule"
    };
    r.DOMAttributeNames = a;
    function c({ type: d, props: o }) {
      const l = document.createElement(d);
      for (const p in o) {
        if (!o.hasOwnProperty(p) || p === "children" || p === "dangerouslySetInnerHTML" || o[p] === void 0) continue;
        const y = a[p] || p.toLowerCase();
        d === "script" && (y === "async" || y === "defer" || y === "noModule") ? l[y] = !!o[p] : l.setAttribute(y, o[p]);
      }
      const { children: u, dangerouslySetInnerHTML: f } = o;
      return f ? l.innerHTML = f.__html || "" : u && (l.textContent = typeof u == "string" ? u : Array.isArray(u) ? u.join("") : ""), l;
    }
    function e(d, o) {
      if (d instanceof HTMLElement && o instanceof HTMLElement) {
        const l = o.getAttribute("nonce");
        if (l && !d.getAttribute("nonce")) {
          const u = o.cloneNode(!0);
          return u.setAttribute("nonce", ""), u.nonce = l, l === d.nonce && d.isEqualNode(u);
        }
      }
      return d.isEqualNode(o);
    }
    function i(d, o) {
      const l = document.getElementsByTagName("head")[0], u = l.querySelector("meta[name=next-head-count]");
      if (process.env.NODE_ENV !== "production" && !u) {
        console.error("Warning: next-head-count is missing. https://nextjs.org/docs/messages/next-head-count-missing");
        return;
      }
      const f = Number(u.content), p = [];
      for (let I = 0, m = u.previousElementSibling; I < f; I++, m = (m == null ? void 0 : m.previousElementSibling) || null) {
        var y;
        (m == null || (y = m.tagName) == null ? void 0 : y.toLowerCase()) === d && p.push(m);
      }
      const O = o.map(c).filter((I) => {
        for (let m = 0, T = p.length; m < T; m++) {
          const L = p[m];
          if (e(L, I))
            return p.splice(m, 1), !1;
        }
        return !0;
      });
      p.forEach((I) => {
        var m;
        return (m = I.parentNode) == null ? void 0 : m.removeChild(I);
      }), O.forEach((I) => l.insertBefore(I, u)), u.content = (f - p.length + O.length).toString();
    }
    (typeof r.default == "function" || typeof r.default == "object" && r.default !== null) && typeof r.default.__esModule > "u" && (Object.defineProperty(r.default, "__esModule", { value: !0 }), Object.assign(r.default, r), t.exports = r.default);
  }(z, z.exports)), z.exports;
}
var W = { exports: {} }, se;
function $e() {
  return se || (se = 1, function(t, r) {
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.cancelIdleCallback = r.requestIdleCallback = void 0;
    const n = typeof self < "u" && self.requestIdleCallback && self.requestIdleCallback.bind(window) || function(c) {
      let e = Date.now();
      return setTimeout(function() {
        c({
          didTimeout: !1,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - e));
          }
        });
      }, 1);
    };
    r.requestIdleCallback = n;
    const a = typeof self < "u" && self.cancelIdleCallback && self.cancelIdleCallback.bind(window) || function(c) {
      return clearTimeout(c);
    };
    r.cancelIdleCallback = a, (typeof r.default == "function" || typeof r.default == "object" && r.default !== null) && typeof r.default.__esModule > "u" && (Object.defineProperty(r.default, "__esModule", { value: !0 }), Object.assign(r.default, r), t.exports = r.default);
  }(W, W.exports)), W.exports;
}
var ce;
function Je() {
  return ce || (ce = 1, function(t, r) {
    "client";
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.handleClientScriptLoad = y, r.initScriptLoader = m, r.default = void 0;
    var n = xe().default, a = Be().default, c = Qe().default, e = a(le), i = ze(), d = We(), o = $e();
    const l = /* @__PURE__ */ new Map(), u = /* @__PURE__ */ new Set(), f = [
      "onLoad",
      "onReady",
      "dangerouslySetInnerHTML",
      "children",
      "onError",
      "strategy"
    ], p = (v) => {
      const { src: w, id: k, onLoad: D = () => {
      }, onReady: _ = null, dangerouslySetInnerHTML: P, children: s = "", strategy: j = "afterInteractive", onError: S } = v, M = k || w;
      if (M && u.has(M))
        return;
      if (l.has(w)) {
        u.add(M), l.get(w).then(D, S);
        return;
      }
      const q = () => {
        _ && _(), u.add(M);
      }, A = document.createElement("script"), V = new Promise((b, H) => {
        A.addEventListener("load", function(R) {
          b(), D && D.call(this, R), q();
        }), A.addEventListener("error", function(R) {
          H(R);
        });
      }).catch(function(b) {
        S && S(b);
      });
      P ? (A.innerHTML = P.__html || "", q()) : s ? (A.textContent = typeof s == "string" ? s : Array.isArray(s) ? s.join("") : "", q()) : w && (A.src = w, l.set(w, V));
      for (const [b, H] of Object.entries(v)) {
        if (H === void 0 || f.includes(b))
          continue;
        const R = d.DOMAttributeNames[b] || b.toLowerCase();
        A.setAttribute(R, H);
      }
      j === "worker" && A.setAttribute("type", "text/partytown"), A.setAttribute("data-nscript", j), document.body.appendChild(A);
    };
    function y(v) {
      const { strategy: w = "afterInteractive" } = v;
      w === "lazyOnload" ? window.addEventListener("load", () => {
        o.requestIdleCallback(() => p(v));
      }) : p(v);
    }
    function O(v) {
      document.readyState === "complete" ? o.requestIdleCallback(() => p(v)) : window.addEventListener("load", () => {
        o.requestIdleCallback(() => p(v));
      });
    }
    function I() {
      [
        ...document.querySelectorAll('[data-nscript="beforeInteractive"]'),
        ...document.querySelectorAll('[data-nscript="beforePageRender"]')
      ].forEach((w) => {
        const k = w.id || w.getAttribute("src");
        u.add(k);
      });
    }
    function m(v) {
      v.forEach(y), I();
    }
    function T(v) {
      const { id: w, src: k = "", onLoad: D = () => {
      }, onReady: _ = null, strategy: P = "afterInteractive", onError: s } = v, j = c(v, [
        "id",
        "src",
        "onLoad",
        "onReady",
        "strategy",
        "onError"
      ]), { updateScripts: S, scripts: M, getIsSsr: q } = e.useContext(i.HeadManagerContext), A = e.useRef(!1);
      e.useEffect(() => {
        const b = w || k;
        A.current || (_ && b && u.has(b) && _(), A.current = !0);
      }, [
        _,
        w,
        k
      ]);
      const V = e.useRef(!1);
      return e.useEffect(() => {
        V.current || (P === "afterInteractive" ? p(v) : P === "lazyOnload" && O(v), V.current = !0);
      }, [
        v,
        P
      ]), (P === "beforeInteractive" || P === "worker") && (S ? (M[P] = (M[P] || []).concat([
        n({
          id: w,
          src: k,
          onLoad: D,
          onReady: _,
          onError: s
        }, j)
      ]), S(M)) : q && q() ? u.add(w || k) : q && !q() && p(v)), null;
    }
    Object.defineProperty(T, "__nextScript", {
      value: !0
    });
    var L = T;
    r.default = L, (typeof r.default == "function" || typeof r.default == "object" && r.default !== null) && typeof r.default.__esModule > "u" && (Object.defineProperty(r.default, "__esModule", { value: !0 }), Object.assign(r.default, r), t.exports = r.default);
  }(U, U.exports)), U.exports;
}
var $, ue;
function Xe() {
  return ue || (ue = 1, $ = Je()), $;
}
var Ye = Xe();
const de = /* @__PURE__ */ Ue(Ye);
function dt({
  appName: t,
  appVersion: r,
  appLanguage: n,
  cookieDomain: a,
  collectorEndpoint: c,
  coreUrl: e,
  cspNonce: i,
  enabled: d,
  userId: o = 0
}) {
  const l = Re(() => Y(), []);
  return d ? /* @__PURE__ */ Fe(Ne, { children: [
    /* @__PURE__ */ ee(de, { id: "tracker-script", nonce: i, children: l }),
    /* @__PURE__ */ ee(
      de,
      {
        nonce: i,
        onLoad: () => {
          const u = window.gptr.tr;
          u !== void 0 && u.install({
            schemaParser: Z(u),
            defaults: {
              businessId: o && String(o) || "",
              appName: t,
              appVersion: r,
              appLanguage: n
            },
            cookieDomain: a,
            srvURL: c
          });
        },
        src: e
      }
    )
  ] }) : null;
}
class lt {
  constructor({
    appName: r,
    appVersion: n,
    appLanguage: a,
    cookieDomain: c,
    collectorEndpoint: e,
    coreUrl: i,
    enabled: d,
    userId: o = 0
  }) {
    C(this, "appName");
    C(this, "appVersion");
    C(this, "cookieDomain");
    C(this, "collectorEndpoint");
    C(this, "coreUrl");
    C(this, "enabled");
    C(this, "injectedScripts");
    C(this, "appLanguage");
    C(this, "userId");
    this.appName = r, this.appVersion = n, this.appLanguage = a, this.cookieDomain = c, this.collectorEndpoint = e, this.coreUrl = i, this.enabled = d, this.userId = o, this.injectedScripts = [];
  }
  initTracker() {
    document.readyState !== "complete" && document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        const r = window.gptr.tr;
        if (r === void 0)
          return;
        r.install({
          schemaParser: Z(r),
          defaults: {
            businessId: this.userId && String(this.userId) || "",
            appName: this.appName,
            appVersion: this.appVersion,
            appLanguage: this.appLanguage
          },
          cookieDomain: this.cookieDomain,
          srvURL: this.collectorEndpoint
        });
      }
    });
  }
  injectTrackerScripts() {
    if (!this.enabled)
      return;
    const r = document.createElement("script");
    r.innerHTML = Y(), r.id = "tracker-script", document.body.append(r);
    const n = document.createElement("script");
    n.src = this.coreUrl, n.async = !0, n.addEventListener("load", this.initTracker.bind(this)), document.body.append(n), this.injectedScripts = [r, n];
  }
  removeInjectedScripts() {
    this.injectedScripts && (this.injectedScripts.forEach((r) => r.remove()), this.injectedScripts = []);
  }
}
const Ze = "request load", Ke = /* @__PURE__ */ new Set(["send ok (200)", "send ok (204)", "send ok (422)"]);
function et() {
  return window.gptr ? window.gptr.tr.getDevLog().reduce(
    // TODO: Fix that
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (t, r) => {
      const n = t.length - 1;
      return r === Ze ? [...t.slice(0, n), [...t[n], r], []] : [...t.slice(0, n), [...t[n], r]];
    },
    [[]]
  ).map(
    (t) => (
      // TODO: Fix that
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      t.reduce((r, n) => Array.isArray(n) ? { ...r, data: n } : Ke.has(n) ? { ...r, status: n } : r, {})
    )
  ).filter(({ data: t }) => !!t) : null;
}
function ft() {
  const t = g((s) => {
    h("eventclick", s);
  }, []), r = g((s) => {
    h("cookieagreementsmodal", s);
  }, []), n = g((s) => {
    h("recruitmentassistant", s);
  }, []), a = g((s) => {
    h("webvitals", s);
  }, []), c = g((s = {}) => {
    h("pageview", s);
  }, []), e = g((s) => {
    h("pageviewtestgroup", s);
  }, []), i = g((s) => {
    h("offerview", s);
  }, []), d = g((s) => {
    h("offerviewclick", s);
  }, []), o = g((s) => {
    h("applyview", s);
  }, []), l = g((s) => {
    h("applyviewsendclick", s);
  }, []), u = g((s) => {
    h("atsclick", s);
  }, []), f = g((s) => {
    h("addfavorites", s);
  }, []), p = g((s) => {
    h("removefavorites", s);
  }, []), y = g((s) => {
    h("ignorerecommendation", s);
  }, []), O = g((s) => {
    h("employersprofileview", s);
  }, []), I = g((s) => {
    h("recommendationview", s);
  }, []), m = g((s) => {
    h("applicationconfirmation", s);
  }, []), T = g((s) => {
    h("erecruiterformfinish", s);
  }, []), L = g((s) => {
    h("currentstepinapplicationprocess", s);
  }, []), v = g(
    (s) => {
      h("currentstepinmultiapplicationprocess", s);
    },
    []
  ), w = g((s) => {
    h("currentstepinauthprocess", s);
  }, []), k = g((s) => {
    h("currentstepinonboardingprocess", s);
  }, []), D = g((s) => {
    h("registrationinformation", s);
  }, []), _ = g((s) => {
    h("showphonenumberclick", s);
  }, []), P = g(() => {
    const s = et();
    return s ? s.filter(({ status: j }) => !j).flatMap(({ data: j }) => j.map(({ payload: S }) => S.schema)).join(`\r
`) : null;
  }, []);
  return {
    sendEventClick: t,
    sendRecruitmentAssistant: n,
    sendCookieAgreementsModal: r,
    sendWebVitals: a,
    sendPageView: c,
    sendPageViewTestGroup: e,
    sendApplyView: o,
    sendApplyViewSendClick: l,
    sendAtsClick: u,
    sendOfferView: i,
    sendOfferViewClick: d,
    sendAddFavorites: f,
    sendRemoveFavorites: p,
    sendIgnoreRecommendation: y,
    sendEmployersProfileView: O,
    sendRecommendationView: I,
    sendApplicationConfirmation: m,
    sendERecruiterFormFinish: T,
    sendCurrentStepInApplicationProcess: L,
    sendCurrentStepInMultiApplicationProcess: v,
    sendCurrentStepInAuthProcess: w,
    sendCurrentStepInOnboardingProcess: k,
    sendRegistrationInformation: D,
    sendShowPhoneNumberClick: _,
    getEventsThatNotComplete: P,
    setTrackerCallback: He,
    setTrackerDefaults: Ve
  };
}
var N = /* @__PURE__ */ ((t) => (t.Offer = "Offer", t.Modal = "Modal", t.ExternalForm = "ExternalForm", t.InfoExternalForm = "InfoExternalForm", t.MultiApplyListing = "MultiApplyListing", t.MultiApplyModal = "MultiApplyModal", t.AuthFirstStep = "AuthFirstStep", t.AuthLogin = "AuthLogin", t.AuthRegister = "AuthRegister", t.ConfirmAccount = "ConfirmAccount", t.ConfirmAccountAndSetUpPassword = "ConfirmAccountAndSetUpPassword", t.AuthPage = "AuthPage", t.ApplyForm = "ApplyForm", t.MiniAtsPracuj = "MiniAtsPracuj", t.MiniAtsFirstStep = "MiniAtsFirstStep", t.MiniAtsSecondStep = "MiniAtsSecondStep", t.SupplementMiniAtsStep = "SupplementMiniAtsStep", t.ErecruiterForm = "ErecruiterForm", t.ErecruiterFormAgreementModal = "ErecruiterFormAgreementModal", t.ErecruiterIntegratedForm = "ErecruiterIntegratedForm", t.ErecruiterQuestionnaireStep = "ErecruiterQuestionnaireStep", t.ThxPage = "ThxPage", t.HistoryBack = "HistoryBack", t.Refresh = "Refresh", t.Unknown = "Unknown", t))(N || {}), tt = /* @__PURE__ */ ((t) => (t.Used = "Used", t.NotUsed = "NotUsed", t.CouldNotUse = "CouldNotUse", t.None = "None", t))(tt || {}), E = /* @__PURE__ */ ((t) => (t.Offer = "Offer", t.MultiApply = "MultiApply", t.LoginBeforeApply = "LoginBeforeApply", t.RegisterBeforeApply = "RegisterBeforeApply", t.ApplyForm = "ApplyForm", t.OldApplyForm = "OldApplyForm", t.ErecruiterApplyForm = "ErecruiterApplyForm", t.ThxPage = "ThxPage", t.AuthPage = "AuthPage", t.OldAuthPage = "OldAuthPage", t.AccountPage = "AccountPage", t.OldAccountPage = "OldAccountPage", t.OneClickApplyModal = "OneClickApplyModal", t))(E || {}), rt = /* @__PURE__ */ ((t) => (t.Agreed = "agreed", t.Disagreed = "disagreed", t.Skipped = "skipped", t))(rt || {}), nt = /* @__PURE__ */ ((t) => (t.MultiStepForm = "MultiStepForm", t.OneClickApply = "OneClickApply", t.SingleStepForm = "SingleStepForm", t))(nt || {}), at = /* @__PURE__ */ ((t) => (t.PL = "pl", t.EN = "en", t.UK = "uk", t))(at || {});
export {
  N as ApplicationStepKinds,
  rt as ErecruiterFormAgreements,
  nt as ErecruiterFormTypes,
  E as EventSource,
  ct as Events,
  at as Languages,
  tt as OneClickApplyStatuses,
  lt as TrackerInit,
  dt as TrackerNext,
  ut as TrackerReact,
  Z as createSchemaParser,
  Y as getTrackerScript,
  h as sendTracker,
  He as setTrackerCallback,
  Ve as setTrackerDefaults,
  ft as useTracker
};
