"use client";
import { useState as l, useCallback as i } from "react";
function f(e, t) {
  const [a, s] = l(!1), [o, u] = l({
    headers: null,
    status: null,
    data: null,
    error: null,
    exception: null
  }), r = i(
    async (c) => {
      s(!0);
      const n = await t(e)(c);
      return u(n), s(!1), n;
    },
    [e, t]
  );
  return { ...o, isLoading: a, handleRequest: r };
}
export {
  f as default
};
